import tw from "twin.macro";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Entry } from "./components/Entry/index";
import { Suspense, useState } from "react";
import { IState } from "./components/Rsvp";
import { submitEntryCode } from "./helpers/api-calls";
import { lazy } from "react";

const Landing = lazy(() =>
  import("./components/Landing").then(({ Landing }) => ({ default: Landing }))
);
const NavBar = lazy(() =>
  import("./components/NavBar").then(({ NavBar }) => ({ default: NavBar }))
);
const BodySection = lazy(() =>
  import("./containers/BodySection").then(({ BodySection }) => ({
    default: BodySection,
  }))
);

const AppContainer = tw.div`
  flex
  flex-col
  w-full
  h-full
  overflow-hidden
  font-family['Montserrat']
`;

function App() {
  const [rsvp, setRsvp] = useState({} as IState);

  const queryParams = new URLSearchParams(window.location.search);

  const isNotEmpty = (rsvp: IState) => {
    return Object.keys(rsvp).length > 0;
  };

  let responseStatus = null;
  const code = queryParams.get("code");
  if (code && !isNotEmpty(rsvp)) {
    responseStatus = submitEntryCode(code, (rsvp) => setRsvp(rsvp));
  }

  return (
    <Router>
      <AppContainer>
        <Routes>
          <Route
            path="/"
            element={
              isNotEmpty(rsvp) ? (
                <Suspense fallback="Please wait...">
                  <NavBar />
                  <Landing
                    firstNamePrimary={rsvp.primaryPerson.firstname}
                    firstNameSecondary={rsvp.partner?.firstname}
                  />
                  <BodySection
                    rsvp={rsvp}
                    updateRsvp={(rsvp: IState) => setRsvp(rsvp)}
                  />
                </Suspense>
              ) : (
                <Entry
                  initRsvp={(rsvp: IState) => setRsvp(rsvp)}
                  initStatusCode={responseStatus}
                />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" replace />}></Route>
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;
