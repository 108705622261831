import tw from "twin.macro";

const Button = tw.button`
  py-5
  md:py-2 
  px-4
  mt-7
  rounded
  w-full
  md:w-auto
  text-lg
`;

export const PrimaryButton = tw(Button)`
  bg-rose-main
  hover:bg-rose-darker
  text-gray-100
`;

export const SecondaryButton = tw(Button)`
  bg-forest-dark
  hover:bg-forest-between
  text-forest-light
`;
