import tw from "twin.macro";

export const FormSectionTitle = tw.h3`
    uppercase  
    tracking-wide  
    text-forest-dark
    font-bold
    mt-7
    mb-3
`;

export const InputLabel = tw.label`
    block 
    uppercase 
    tracking-wide 
    text-forest-dark
    font-bold 
    mt-7
    mb-3
`;

export const TextInput = tw.input`
    appearance-none 
    block 
    w-full 
    bg-gray-200 
    text-gray-700 
    border 
    rounded 
    py-3
    px-4 
    leading-tight 
    focus:outline-none 
    focus:bg-white
    focus:border-gray-500
`;

export const MultilineInput = tw.textarea`
    appearance-none 
    block 
    w-full 
    bg-gray-200 
    text-gray-700 
    border 
    rounded 
    py-3 
    px-4 
    leading-tight 
    focus:outline-none 
    focus:bg-white
    focus:border-gray-500
`;

export const InputError = tw.span`
    block
    w-full
    py-2
    text-lg
    text-red-600
`;

export const InputSuccess = tw.span`
    block
    w-full
    py-2
    text-lg
    text-green-600
`;
