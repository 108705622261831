import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import tw from "twin.macro";
import { PrimaryButton } from "../Shared/buttons";
import { Title } from "../Shared/containers";
import { InputError, TextInput } from "../Shared/inputs";
import { submitEntryCode } from "./../../helpers/api-calls";
import { useTranslation } from "react-i18next";

const Container = tw.div`
    mt-10
    w-10/12
    md:w-1/2
    align-self[center]
`;

export function Entry(props: { initRsvp: any; initStatusCode: number }) {
  const { t } = useTranslation(["entry"]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [code, setCode] = useState({
    value: searchParams.get("code") || "",
  });

  const getErrorMessage = (statusCode: number) => {
    if (statusCode === 404) {
      return t("errors.wrong_code");
    } else if (statusCode > 0 && statusCode !== 200) {
      return t("errors.general_error");
    }
    return null;
  };

  const [error, setError] = useState(getErrorMessage(props.initStatusCode));

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSearchParams({ code: code.value });
    const responseStatus = await submitEntryCode(code.value, (rsvp) =>
      props.initRsvp(rsvp)
    );

    setError(getErrorMessage(responseStatus));
  };

  return (
    <Container>
      <Title>{t("greeting", { ns: "entry" })}</Title>
      <p className="py-5">{t("code", { ns: "entry" })}</p>
      <form onSubmit={handleSubmit}>
        <TextInput
          type="text"
          value={code.value}
          onChange={(event: any) => setCode({ value: event.target.value })}
        ></TextInput>
        <InputError>{error}</InputError>
        <PrimaryButton type="submit">
          {t("send", { ns: "entry" })}
        </PrimaryButton>
      </form>
    </Container>
  );
}
