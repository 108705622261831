export const submitEntryCode = async (code: string, initRsvp: any) => {
  const url = `https://smueslisbe.herokuapp.com/api/guests/${code}`;
  const response = await fetch(url, {
    method: "GET",
  });

  if (response.ok) {
    const content = await response.json();
    initRsvp(content);
  }

  return response.status;
};
